import { createColumnHelper } from "@tanstack/react-table";
import { Key, useState } from "react";
import { useTranslation } from "react-i18next";
import { JSX } from "react/jsx-runtime";

import { Button } from "components/Button";
import { DashboardHeader } from "components/DashboardHeader";
import { FormNewJob } from "components/FormNewJob";
import { Modal } from "components/Modal";
import { ReportCard } from "components/PageReports";
import {
  Table,
  TableFiltersMobile,
  TablePagination,
  TableTop,
} from "components/Table";

import { useHead } from "lib/hooks/useHead";
import { usePagination } from "lib/hooks/usePagination";
import { useGetReportsQuery } from "lib/slices/mockJsonAPISlice";

import styles from "./PageReport.module.scss";

const columnHelper = createColumnHelper();

export function PageReports() {
  const { t } = useTranslation();
  const { data } = useGetReportsQuery();
  const [newJobModalOpen, setNewJobModalOpen] = useState(false);
  useHead(t("pages.reports.header"));

  const columns = [
    columnHelper.accessor("creationDate", {
      header: t("pages.reports.tableColumns.creationDate"),
      filterType: "date",
      // @ts-expect-error inDateRange is custom
      filterFn: "inDateRange",
    }),
    columnHelper.accessor("state", {
      header: t("pages.reports.tableColumns.state"),
      // @ts-expect-error checkboxes is custom
      filterType: "checkboxes",
      filterFn: "arrIncludesSome",
    }),
    columnHelper.accessor("type", {
      header: t("pages.reports.tableColumns.type"),
      // @ts-expect-error checkboxes is custom
      filterType: "checkboxes",
      filterFn: "arrIncludesSome",
    }),
    columnHelper.accessor("timePeriod.to", {
      header: t("pages.reports.tableColumns.to"),
      filterType: "date",
      // @ts-expect-error inDateRange is custom
      filterFn: "inDateRange",
    }),
    columnHelper.accessor("timePeriod.from", {
      header: t("pages.reports.tableColumns.from"),
      filterType: "date",
      // @ts-expect-error inDateRange is custom
      filterFn: "inDateRange",
    }),
  ];

  return (
    <>
      <main className={styles.main}>
        <DashboardHeader title={t("pages.reports.header")} />
        {data && (
          <Table
            data={data}
            columns={columns}
            pageSize={12}
            CustomTable={({ table }) => {
              const pagesToRender = usePagination(table);
              return (
                <div className={styles.tableContainer}>
                  <div className={styles.header}>
                    <TableTop
                      table={table}
                      ctaBtn={
                        <Button
                          text={t("pages.reports.addNewJob")}
                          intent="primary"
                          icon={{ name: "add", size: 18, intent: "white" }}
                          onClick={() => setNewJobModalOpen(true)}
                        />
                      }
                    />
                    <TableFiltersMobile table={table} />
                  </div>
                  <div className={styles.content}>
                    {table.getRowModel().rows.map(
                      (
                        row: {
                          original: JSX.IntrinsicAttributes & {
                            type: any;
                            state: any;
                            creationDate: any;
                            timePeriod: any;
                            progress: any;
                          };
                        },
                        index: Key | null | undefined,
                      ) => (
                        <ReportCard key={index} {...row.original} />
                      ),
                    )}
                  </div>
                  <div className={styles.footer}>
                    <TablePagination
                      table={table}
                      pagesToRender={pagesToRender}
                    />
                  </div>
                </div>
              );
            }}
          />
        )}
      </main>
      <Modal isOpen={newJobModalOpen} onClose={() => setNewJobModalOpen(false)}>
        <div className={styles.formContainer}>
          <div className={styles.header}>
            <h2>{t("pages.reports.addNewJob")}</h2>
            <p>{t("pages.reports.fillOutForm")}</p>
          </div>
          <FormNewJob />
          <div className={styles.btnContainer}>
            <Button
              text={t("save")}
              intent="primary"
              type="submit"
              form="new-job-form"
              icon={{ name: "floppydisk", intent: "white" }}
              slim={true}
            />
            <Button
              text={t("cancel")}
              icon={{ name: "delete1", size: 16 }}
              slim={true}
              onClick={() => setNewJobModalOpen(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
