import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { DashboardHeader } from "components/DashboardHeader";
import { FormNewAPIKey } from "components/FormNewAPIKey";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";
import { APIKey, TableAPIKeysMobile } from "components/PageAPIKeys";
import { Table } from "components/Table";
import { Toggle } from "components/Toggle";

import { copyToClipboard } from "lib/helpers";
import { useHead } from "lib/hooks/useHead";
import { useGetAPIKeysQuery } from "lib/slices/mockJsonAPISlice";

import styles from "./PageAPIKeys.module.scss";

const columnHelper = createColumnHelper();

export function PageAPIKeys() {
  const { t } = useTranslation();
  const { data } = useGetAPIKeysQuery();
  const [modalOpen, setModalOpen] = useState(false);
  useHead(t("pages.apiKeys.header"));

  const columns = [
    columnHelper.display({
      id: "icon",
      size: 50,
      cell: () => <Icon name="key" intent="black" />,
    }),
    columnHelper.accessor("enabled", {
      header: t("pages.apiKeys.tableColumns.state"),
      filterType: "checkboxes",
      filterFn: "arrIncludesSome",
      cell: (info) => (
        <div className={styles.stateColumn}>
          <Toggle checked={info.getValue()} onChange={() => undefined} />
        </div>
      ),
    }),
    columnHelper.accessor("apiKey", {
      header: t("pages.apiKeys.tableColumns.apiKey"),
      filterType: "text",
      cell: (info) => <APIKey apiKey={info.getValue()} />,
    }),
    columnHelper.display({
      id: "copy",
      size: 50,
      header: t("pages.apiKeys.tableColumns.copy"),
      cell: (info) => (
        <Button
          intent="clear"
          icon={{ name: "copypaste" }}
          onClick={() => copyToClipboard(info.row.original.apiKey)}
        />
      ),
    }),
    columnHelper.accessor("readOnly", {
      header: t("pages.apiKeys.tableColumns.readOnly"),
      filterType: "checkboxes",
      filterFn: "arrIncludesSome",
      cell: (info) => (
        <input type="checkbox" defaultChecked={info.getValue()} />
      ),
    }),
  ];

  return (
    <>
      <main className={styles.main}>
        <DashboardHeader title={t("pages.apiKeys.header")} />
        {data && (
          <Table
            data={data}
            columns={columns}
            header={true}
            mobileElement={TableAPIKeysMobile}
            headerCta={
              <Button
                onClick={() => setModalOpen(true)}
                text={t("pages.apiKeys.newApiKey")}
                icon={{ name: "add", size: 16 }}
              />
            }
          />
        )}
      </main>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <h2>{t("pages.apiKeys.newApiKey")}</h2>
            <p>{t("newClaim.subHeader")}</p>
          </div>
          <FormNewAPIKey />
          <div className={styles.btnContainer}>
            <Button
              text={t("create")}
              intent="primary"
              type="submit"
              form="new-apikey-form"
              icon={{ name: "floppydisk", intent: "white" }}
              slim={true}
            />
            <Button
              text={t("cancel")}
              icon={{ name: "delete1", size: 16 }}
              slim={true}
              onClick={() => setModalOpen(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
