import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import { RInioButton } from "ui-core";
import "ui-core";

import { Badge } from "components/Badge";
import { DropdownUser } from "components/Dropdowns";
import { Icon } from "components/Icon";

import styles from "./DashboardHeader.module.scss";

type Props = {
  title: string;
  subHeader?: string | JSX.Element;
  badge?: string;
  backBtn?: boolean;
  backBtnClass?: string;
  backIconSize?: number;
};

export function DashboardHeader({ title, subHeader, badge, backBtn }: Props) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      // Edge case a user lands on a page and has no history stack
      navigate("/entities");
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.titleContainer}>
        {backBtn && (
          <RInioButton intent="clear" onClick={handleGoBack}>
            <Icon name="chevronLeft" size={14} />
          </RInioButton>
        )}
        <div>
          <h1>{title}</h1>

          <div className={styles.subHeader}>
            {typeof subHeader !== "string" ? (
              <div>{subHeader}</div>
            ) : (
              <span>{subHeader}</span>
            )}
            {badge && <Badge status={badge} />}
          </div>
        </div>
      </div>
      {user && (
        <DropdownUser title={user.profile.email} user={user} accounts={[]} />
      )}
    </header>
  );
}
