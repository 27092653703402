import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./TableErrorMessage.module.scss";

type Props = {
  error: string;
  message: string;
};

export function TableErrorMessage({ error, message }: Props) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={classNames(styles.errorMessageContainer, {
        [styles.errorMessageContainerExpanded]: expanded,
      })}
    >
      <span>{t("error")}</span>
      <p>[{error}]:</p>
      {expanded && <p>{message}</p>}
      <Icon
        name={expanded ? "invisible" : "visible"}
        size={14}
        onClick={() => setExpanded(!expanded)}
      />
    </div>
  );
}
