import { useState } from "react";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./Chip.module.scss";

type Props = {
  text: string;
  onClick?: (selected: boolean) => void;
  intent: string;
  selected?: boolean;
  deleteIcon?: boolean;
  slim: boolean;
};

export function Chip({
  text,
  onClick,
  intent = "primary",
  selected = false,
  deleteIcon,
  slim,
}: Props) {
  const [_selected, setSelected] = useState(selected);

  const primary = intent === "primary";
  const secondary = intent === "secondary";
  const success = intent === "success";
  const danger = intent === "danger";
  const warning = intent === "warning";

  function _onClick() {
    setSelected(!_selected);
    onClick?.(!_selected);
  }

  return (
    <button
      className={classNames(styles.chip, {
        [styles.chipPrimary]: primary,
        [styles.chipPrimarySelected]: primary && _selected,
        [styles.chipSecondary]: secondary && _selected,
        [styles.chipSecondarySelected]: secondary && _selected,
        [styles.chipSuccess]: success,
        [styles.chipDanger]: danger,
        [styles.chipWarning]: warning,
        [styles.chipSlim]: slim,
      })}
      onClick={onClick ? _onClick : undefined}
    >
      {text}
      {deleteIcon && _selected && <Icon name="delete1" size={14} />}
    </button>
  );
}
