import { useRef, useState } from "react";

import { Icon, IconProps } from "components/Icon";

import { classNames } from "lib/classNames";
import { useOutsideClick } from "lib/hooks/useOutsideClick";

import styles from "./Dropdown.module.scss";

type Props = {
  btnElement: JSX.Element;
  icon?: IconProps;
  noChevron?: boolean;
  width?: number;
  children: JSX.Element | ((c: any) => void);
  backgroundColor?: string;
  fillHeight?: boolean;
  theme?: "select";
  onChange?: () => void;
  disableOutsideClick?: boolean;
  hasError?: boolean;
};

export function Dropdown({
  btnElement,
  icon,
  noChevron,
  width,
  children,
  backgroundColor,
  fillHeight,
  theme,
  onChange,
  disableOutsideClick = false,
  hasError = false,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    onChange?.();
  };

  const buttonRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useOutsideClick(buttonRef, () => {
    if (!disableOutsideClick && isOpen) {
      setIsOpen(false);
      onChange?.();
    }
  });

  return (
    <div ref={buttonRef} className={styles.dropdownContainer} style={{ width }}>
      <button
        type="button"
        style={{ backgroundColor }}
        className={classNames(styles.dropdown, {
          [styles.dropdownActive]: theme !== "select" && isOpen,
          [styles.dropdownSelect]: theme === "select",
          [styles.dropdownSelectActive]: theme === "select" && isOpen,
          [styles.dropdownError]: hasError,
          [styles.dropdownFillHeight]: fillHeight ? true : false,
        })}
        onClick={toggleDropdown}
      >
        <div>
          {icon && <Icon {...icon} />}
          {btnElement}
        </div>

        {!noChevron && (
          <span
            className={classNames(styles.iconContainer, {
              [styles.iconContainerOpen]: isOpen,
            })}
          >
            <Icon name="chevronRight" intent="black" size={12} />
          </span>
        )}
      </button>

      <div
        className={classNames(styles.dropdownContent, {
          [styles.dropdownContentOpen]: isOpen,
        })}
      >
        {/* @ts-expect-error type error */}
        {typeof children === "function"
          ? children(() => setIsOpen(false))
          : children}
      </div>
    </div>
  );
}
