import { flexRender } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import styles from "./TableClientsMobile.module.scss";

export function TableClientsMobile({ rowData }: any) {
  const { t } = useTranslation();

  const clientName = rowData.find((row: any) => row.column.id === "name");
  const legalEntity = rowData.find(
    (row: any) => row.column.id === "legalEntity",
  );
  const orgNr = rowData.find((row: any) => row.column.id === "orgNr");
  const address = rowData.find((row: any) => row.column.id === "address");
  const visibleContactAddress = rowData.find(
    (row: any) => row.column.id === "visibleContactAddress",
  );
  const visibleContactPhone = rowData.find(
    (row: any) => row.column.id === "visibleContactPhone",
  );
  const website = rowData.find((row: any) => row.column.id === "website");

  const bgBankAccount = rowData.find(
    (row: any) => row.column.id === "bankAccount",
  );
  const vatNo = rowData.find((row: any) => row.column.id === "vatNo");
  const actions = rowData.find((row: any) => row.column.id === "actions");

  return (
    <div className={styles.cardContainer}>
      <div className={styles.header}>
        <div>
          <p>
            <strong>
              {t("pages.superAdmin.clients.tableColumns.clientName")}:
            </strong>
            {clientName.renderValue()}
          </p>
          <p>
            <strong>
              {t("pages.superAdmin.clients.tableColumns.legalEntity")}:
            </strong>
            {legalEntity.renderValue()}
          </p>
          <p>
            <strong>{t("pages.superAdmin.clients.tableColumns.orgNr")}:</strong>
            {orgNr.renderValue()}
          </p>

          <p>
            <strong>{t("pages.superAdmin.clients.tableColumns.email")}:</strong>
            {visibleContactAddress.renderValue()}
          </p>
          <p>
            <strong>{t("pages.superAdmin.clients.tableColumns.phone")}:</strong>
            {visibleContactPhone.renderValue()}
          </p>

          <p>
            <strong>
              {t("pages.superAdmin.clients.tableColumns.bgBankAccount")}:
            </strong>
          </p>
          {flexRender(
            bgBankAccount.column.columnDef.cell,
            bgBankAccount.getContext(),
          )}
          <p>
            <strong>{t("pages.superAdmin.clients.tableColumns.vat")}:</strong>
            {vatNo.renderValue()}
          </p>
        </div>
        <div>
          <strong>
            <p>{t("pages.superAdmin.clients.tableColumns.address")}:</p>
          </strong>
          {flexRender(address.column.columnDef.cell, address.getContext())}
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          <p>
            <strong>
              {t("pages.superAdmin.clients.tableColumns.website")}:
            </strong>
            {website.renderValue()}
          </p>
        </div>
        <div>
          {flexRender(actions.column.columnDef.cell, actions.getContext())}
        </div>
      </div>
    </div>
  );
}
