import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";

import { Form } from "components/Form";

export function FormNewJob() {
  const { t } = useTranslation();

  const formData = {
    validator,
    uiSchema: {
      "ui:submitButtonOptions": {
        parentProps: {
          hidden: true,
        },
      },
      taskType: {
        "ui:widget": "select",
        "ui:placeholder": t("newClaim.type"),
      },
    },
    schema: {
      type: "object",
      required: ["taskType"],
      properties: {
        taskType: {
          type: "string",
          title: t("pages.reports.taskType"),
          enum: [
            t("pages.reports.paymentReport"),
            t("pages.reports.fileReport"),
          ],
        },
      },
    },
  };

  return <Form id="new-job-form" {...formData} />;
}
