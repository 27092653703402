import { createElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ChartBarsHorizontal } from "assets/svgs/chart-bars-horizontal.svg";
import { ReactComponent as ChartBarsVertical } from "assets/svgs/chart-bars-vertical.svg";
import { ReactComponent as ChartDonut } from "assets/svgs/chart-donut.svg";
import { ReactComponent as ChartLineSmooth } from "assets/svgs/chart-line-smooth.svg";
import { ReactComponent as ChartLine } from "assets/svgs/chart-line.svg";
import { ReactComponent as ChartPie } from "assets/svgs/chart-pie.svg";

import { Filters } from "components/Filters";
import { DateWidget } from "components/Form/DateWidget";
import { Graph } from "components/Graph/Graph";
import {
  selectWidgetById,
  updateDataById,
} from "components/WidgetPanel/widgetPanelSlice";

import { camelCaseToNormal } from "lib/helpers";

import styles from "./GraphEdit.module.scss";

const CHARTS = [
  { title: "Pie", img: ChartPie, type: "pie" },
  { title: "Line", img: ChartLine, type: "line" },
  { title: "Smooth Line", img: ChartLineSmooth, type: "smoothLine" },
  { title: "Vertical Bar", img: ChartBarsVertical, type: "bar" },
  { title: "Horizontal Bar", img: ChartBarsHorizontal, type: "barHorizontal" },
  { title: "Doughnut", img: ChartDonut, type: "doughnut" },
];

type Props = {
  id: number;
  filters: Graph["filters"];
  storageKey: keyof Widgets;
  closeModal: () => void;
};

export function GraphEdit({ id, filters, storageKey, closeModal }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const widget = useSelector(({ widgetPanel }) =>
    selectWidgetById(widgetPanel, storageKey, id),
  );
  const [selectedGraph, setSelectedGraph] = useState(widget.type);

  // Used to add 'options' key to graphs object to ensure
  // we can keep the original yAxisKey values after modifying them
  useEffect(() => {
    if (!widget.options) {
      dispatch(
        updateDataById({
          id: widget.id,
          data: {
            ...widget,
            options: widget.yAxisKey,
          },
          storageKey,
        }),
      );
    }
  }, [widget]);

  const onGraphChange = (chartType: string) => {
    setSelectedGraph(chartType);
    const smoothLine = chartType === "smoothLine";
    const newChartData = {
      ...widget,
      renderContent: () => <Graph data={newChartData} />,
      type: smoothLine ? "line" : chartType,
      properties: {
        ...widget.properties,
        lineType: smoothLine ? "monotone" : "linear",
      },
    };

    dispatch(
      updateDataById({
        id: widget.id,
        data: newChartData,
        storageKey,
      }),
    );
  };

  const onOptionsChange = (value: any) => {
    const newChartData = {
      ...widget,
      renderContent: () => <Graph data={newChartData} />,
      yAxisKey: value,
      options: widget.options ?? widget.yAxisKey,
    };

    dispatch(
      updateDataById({
        id: widget.id,
        data: newChartData,
        storageKey,
      }),
    );
  };

  return (
    <div className={styles.graphEditContainer}>
      <div>
        <div>
          <Filters
            title={t("graphEdit.options")}
            onChange={onOptionsChange}
            filters={
              widget.options
                ? widget.options.map((o: string) => ({
                    value: o,
                    label: camelCaseToNormal(o),
                  }))
                : []
            }
            value={widget.yAxisKey}
          />
        </div>
      </div>
      <div>
        <p className={styles.header}>{t("graphEdit.chartTypes")}</p>
        <div className={styles.graphsContainer}>
          {CHARTS.map((chart, index) => (
            <button
              key={index}
              className={styles.graphType}
              onClick={() => onGraphChange(chart.type)}
              disabled={!filters.graphs.includes(chart.type)}
            >
              <div>
                <div className={styles.selectBtn}>
                  {selectedGraph === chart.type && <span></span>}
                </div>
                <p>{chart.title}</p>
              </div>
              <div className={styles.imgContainer}>
                {createElement(chart.img)}
              </div>
            </button>
          ))}
        </div>
      </div>
      {filters.date && (
        <div>
          <p className={styles.header}>{t("graphEdit.dateRange")}</p>
          <div>
            <DateWidget
              inline
              range
              onChange={() => undefined}
              onSetDate={closeModal}
            />
          </div>
        </div>
      )}
    </div>
  );
}
