import { useTranslation } from "react-i18next";

import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
} from "recharts";

import { GraphTooltip } from "components/GraphTooltip";
import { Icon } from "components/Icon";
import { Tooltip as CTooltip } from "components/Tooltip";

import "lib/colors";
import { COLORS } from "lib/colors";

import styles from "./CardKPI.module.scss";

function TrendIndicator({ positive }: { positive: boolean }) {
  return (
    <div
      className={styles.trendIndicator}
      style={{ backgroundColor: positive ? COLORS.valid : COLORS.invalid }}
    >
      <Icon
        name={positive ? "grapharrowincrease" : "grapharrowdecrease"}
        intent="white"
        size={15}
      />
    </div>
  );
}

type Props = {
  type: string;
  percentage: number;
  progress: number;
  graphData: any;
};

export function CardKPI({ type, percentage, graphData }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardContainer}>
      <div className={styles.header}>
        <div>
          <div className={styles.titleContainer}>
            <p>{t(`cardKPI.${type}.title`)}</p>
            <CTooltip
              title={t(`cardKPI.${type}.title`)}
              text={t(`cardKPI.${type}.description`)}
            >
              <Icon name="informationcircle" size={14} />
            </CTooltip>
          </div>
          <p className={styles.type}>{type}</p>
        </div>
        <div className={styles.trendContainer}>
          <div>
            <p className={styles.percentage}>{`${percentage}%`}</p>
            <TrendIndicator positive={percentage > 0} />
          </div>
          <p>{t("cardKPI.last5Days")}</p>
        </div>
      </div>

      <div className={styles.graphContainer}>
        <ResponsiveContainer height={300}>
          <AreaChart data={graphData}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="44.33%" stopColor={COLORS.blueLight} />
                <stop offset="70.32%" stopColor={COLORS.blueLight} />
                <stop offset="100%" stopColor="rgba(78, 195, 224, 0.31)" />
              </linearGradient>
            </defs>
            <XAxis
              tickLine={false}
              dataKey="name"
              tickMargin={14}
              interval={0}
            />
            <YAxis tickLine={false} tickMargin={12} />
            <CartesianGrid
              strokeDasharray="4 4"
              vertical={false}
              stroke={COLORS.grayLight}
            />
            <Tooltip
              cursor={false}
              // @ts-expect-error type error
              content={GraphTooltip}
              isAnimationActive={false}
            />
            <Area
              name="value"
              dot={false}
              activeDot={{ r: 8 }}
              dataKey="value"
              isAnimationActive={false}
              stroke={COLORS.blueLight}
              strokeWidth={2}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
