import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { DashboardHeader } from "components/DashboardHeader";
import { CaseInformation } from "components/PageCase/CaseInformation/CaseInformation";
import { CustomerCard } from "components/PageCase/CustomerCard";

import { readableId } from "lib/helpers";
import { useGetCaseByIdQuery } from "lib/slices/clientServiceAPISlice";

import styles from "./PageCase.module.scss";

export function PageCase() {
  const { t } = useTranslation();
  const { caseId } = useParams();
  const { data: caseData, isLoading } = useGetCaseByIdQuery(caseId as string);

  return (
    <main className={styles.main}>
      <DashboardHeader
        title={t("pages.case.caseTitle", {
          caseId: readableId(caseId),
        })}
        subHeader={
          <>
            <span>{t("pages.case.customerTitle")}</span>
            <span>&#8594;</span>
            <span>{caseData?.customer.customerName}</span>
            <span>&#8594;</span>
            <span>{readableId(caseId)}</span>
          </>
        }
        backBtn={true}
      />

      <section className={styles.caseContainer}>
        <CustomerCard caseData={caseData} isLoading={isLoading} />
        <CaseInformation caseData={caseData} isLoading={isLoading} />
      </section>
    </main>
  );
}
