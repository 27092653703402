import { ChangeEvent } from "react";
import { Link } from "react-router-dom";

import { BaseInputTemplateProps } from "@rjsf/utils";

import { Icon } from "components/Icon";

import styles from "./BaseInputTemplate.module.scss";

/**
 * This component extends the BaseInputTemplate from rjsf.
 * https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates#baseinputtemplate
 */
export function BaseInputTemplate(props: BaseInputTemplateProps) {
  const {
    id,
    type,
    placeholder,
    disabled,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    rawErrors,
    value,
    icon,
    uiSchema,
    schema,
  } = props;

  const onTextChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange?.(value === "" ? undefined : value);
  };
  const onTextBlur = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onBlur?.(id, value);
  const onTextFocus = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onFocus?.(id, value);

  const hasError = rawErrors && rawErrors.length > 0;

  return (
    <div className={styles.inputContainer}>
      <div className={styles[`input${hasError ? "-error" : ""}`]}>
        {icon && <Icon name={icon} className={styles.icon} intent="black" />}
        <input
          id={id}
          name={props.name}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={value}
          onChange={onChangeOverride || onTextChange}
          style={{ paddingLeft: icon ? 45 : undefined }}
          autoComplete={uiSchema?.["ui:autocomplete"]}
          onBlur={onTextBlur}
          onFocus={onTextFocus}
          maxLength={schema?.maxLength}
          minLength={schema?.minLength}
        />
        <div className={styles.bottomContainer}>
          {uiSchema?.["ui:link"] && (
            <Link className={styles.link} to={uiSchema["ui:link"].href}>
              {uiSchema["ui:link"].text}
            </Link>
          )}
        </div>
        {hasError && (
          <Icon
            name="Uwarningoctagon"
            className={styles.errorIcon}
            intent="invalid"
          />
        )}
      </div>
    </div>
  );
}
