import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import { icons } from "assets/icons";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./Sidebar.module.scss";

type Props = {
  icon: keyof typeof icons;
  href?: string;
  text: string;
  subMenu?: {
    textKey: string;
    href: string;
  }[];
  sidebarOpen: boolean;
  openSidebar: (value: boolean) => void;
};

export function SidebarLink({
  icon,
  href,
  text,
  subMenu,
  sidebarOpen,
  openSidebar,
}: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const onAnalyticsRoute =
    text === "Analytics" && location.pathname.includes("analytics");
  const onSettingsRoute =
    text === "Settings" && location.pathname.includes("settings");

  const selected = location.pathname === href;

  const onClick = () => {
    if (!sidebarOpen) {
      openSidebar(true);
      setExpanded(true);
    } else {
      setExpanded(!expanded);
    }
  };

  useEffect(() => {
    if (onAnalyticsRoute || onSettingsRoute) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [location]);

  if (subMenu) {
    return (
      <div>
        <button
          onClick={onClick}
          className={classNames(styles.sidebarBtn, {
            [styles.sidebarBtnExpanded]: expanded,
            [styles.sidebarBtnOpen]: sidebarOpen,
          })}
        >
          <Icon name={icon} />
          <div>
            <p>{text}</p>
            <Icon
              name="chevronRight"
              size={12}
              intent="white"
              className={classNames(styles.sidebarBtnChevron, {
                [styles.sidebarBtnChevronActive]: expanded,
              })}
            />
          </div>
        </button>
        <div
          className={classNames(styles.sidebarSubmenu, {
            [styles.sidebarSubmenuOpen]: expanded,
          })}
        >
          {sidebarOpen &&
            subMenu.map((option, i) => (
              <NavLink
                key={i}
                to={option.href}
                className={({ isActive }) =>
                  classNames({
                    [styles.sidebarSubmenuActive]: isActive,
                  })
                }
              >
                {t(option.textKey)}
              </NavLink>
            ))}
        </div>
      </div>
    );
  }

  return (
    <NavLink
      to={href as string}
      className={({ isActive }) =>
        classNames(styles.sidebarLink, {
          [styles.sidebarLinkOpen]: sidebarOpen,
          [styles.sidebarLinkSelected]:
            isActive ||
            // Hack to make /cases active
            (location.pathname === "/cases" && href === "/customers") ||
            (/\/customer\/\w+/g.test(location.pathname) && href === "/"),
        })
      }
    >
      <Icon name={icon} intent={selected ? "black" : "white"} />
      <p>{text}</p>
    </NavLink>
  );
}
