import { useMemo } from "react";

import { WidgetProps } from "@rjsf/utils";

import { Dropdown } from "components/Dropdowns";
import { Icon } from "components/Icon";

import styles from "./SelectWidget.module.scss";

/**
 * Select Component
 * A select and multi-select component replacing rjsf's SelectWidget.
 *
 * uiSchema:
 * @param {string} [props.uiSchema.icon] - Optional icon name to display
 */
export function SelectWidget(props: WidgetProps) {
  const { options, onChange, value, uiSchema, multiple, rawErrors } = props;

  const hasSelected = useMemo(() => {
    return multiple ? value.length > 0 : value !== undefined;
  }, [multiple, value]);

  function handleOnChange(optionValue: any) {
    if (multiple) {
      const newValue = value.includes(optionValue)
        ? value.filter((v: any) => v !== optionValue)
        : [...value, optionValue];
      onChange(newValue);
    } else {
      optionValue === value ? onChange(undefined) : onChange(optionValue);
    }
  }

  function isOptionSelected(option: any) {
    return (multiple && value.includes(option)) || value === option;
  }

  return (
    <>
      <Dropdown
        theme="select"
        icon={uiSchema?.icon}
        hasError={rawErrors && rawErrors.length > 0}
        btnElement={
          <p className={styles.selectBtn}>
            {hasSelected
              ? multiple
                ? value.join(" ")
                : value
              : (uiSchema?.["ui:placeholder"] ?? "Select")}
          </p>
        }
        children={(closeDropdown) => (
          <div
            className={styles.optionsContainer}
            role="listbox"
            aria-labelledby={props.id}
            aria-expanded={false}
          >
            {options.enumOptions?.map((option, i) => (
              <button
                key={i}
                value={option.value}
                type="button"
                onClick={() => {
                  handleOnChange(option.value);
                  closeDropdown();
                }}
                className={
                  isOptionSelected(option.value)
                    ? styles.optionSelected
                    : styles.option
                }
                role="option"
                aria-selected={isOptionSelected(option.value)}
              >
                {option.label}
                {isOptionSelected(option.value) && (
                  <Icon name="check" intent="blue-light" size={16} />
                )}
              </button>
            ))}
          </div>
        )}
      />
    </>
  );
}
