import { useTranslation } from "react-i18next";

import { Chip } from "components/Chip";
import { Icon } from "components/Icon";
import { ProgressCircle } from "components/Progress/ProgressCircle";

import { COLORS } from "lib/colors";
import { formatDateToYYYYMMDD } from "lib/dateUtils";

import styles from "./ReportCard.module.scss";

const STATE_TO_INTENT = {
  error: { intent: "danger", color: COLORS.invalid },
  success: { intent: "success", color: COLORS.valid },
  pending: { intent: "warning", color: COLORS.warning },
};

type Props = {
  type: string;
  state: keyof typeof STATE_TO_INTENT;
  creationDate: string;
  timePeriod: {
    from: string;
    to: string;
  };
  progress: string;
};

export function ReportCard({
  type,
  state,
  creationDate,
  timePeriod,
  progress,
}: Props) {
  const { t } = useTranslation();
  const creation = new Date(creationDate);
  const formattedDate = `${formatDateToYYYYMMDD(
    creation,
  )} ${creation.getUTCHours()}:${creation.getUTCMinutes()}:${creation.getUTCSeconds()}`;

  return (
    <div className={styles.reportCard}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <div className={styles.icon}>
            <Icon name="arrowdown" intent="black" size={18} />
          </div>
          <h3>{t("reportCard.report", { type })}</h3>
        </div>
        <div className={styles.content}>
          <div>
            <span>{t("reportCard.state")}</span>
            <p>
              <Chip
                text={state}
                slim={true}
                intent={STATE_TO_INTENT[state].intent}
              />
            </p>
          </div>
          <div>
            <span>{t("reportCard.creationDate")}</span>
            <p className={styles.date}>{formattedDate}</p>
          </div>
          <div className={styles.timePeriod}>
            <span>{t("reportCard.timePeriod")}</span>
            <p>{`from: ${timePeriod.from}`}</p>
            <p>{`to: ${timePeriod.to}`}</p>
          </div>
        </div>
      </div>
      <div className={styles.percentageContainer}>
        <ProgressCircle
          progress={progress}
          color={STATE_TO_INTENT[state].color}
        />
      </div>
    </div>
  );
}
