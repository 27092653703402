import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { DashboardHeader } from "components/DashboardHeader";
import { resetGraphs } from "components/WidgetPanel/widgetPanelSlice";
import { WidgetsContainer } from "components/WidgetsContainer";

import { useHead } from "lib/hooks/useHead";
import { useGetOutboundCommunicationQuery } from "lib/slices/mockJsonAPISlice";

import styles from "./PageOutbound.module.scss";

export function PageOutbound() {
  const { t } = useTranslation();
  const { data, refetch } = useGetOutboundCommunicationQuery();
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState(data);
  useHead(t("pages.analytics.outbound.header"));

  const resetAllGraphs = async () => {
    const { data } = await refetch();

    dispatch(
      resetGraphs({
        storageKey: "outboundSuccessData",
        originalData: data.deliverySuccess,
      }),
    );

    dispatch(
      resetGraphs({
        storageKey: "outboundBounceData",
        originalData: data.deliveryBounce,
      }),
    );

    dispatch(
      resetGraphs({
        storageKey: "outboundPortfolioData",
        originalData: data.portfolioSummary,
      }),
    );

    setGraphData({ ...data });
  };

  return (
    <main className={styles.main}>
      <DashboardHeader title={t("pages.analytics.header")} />
      <WidgetsContainer
        header={t("pages.analytics.outbound.header")}
        subHeader={t("pages.analytics.outbound.successRates")}
        graphData={graphData ? [...graphData.deliverySuccess] : undefined}
        storageKey="outboundSuccessData"
        customReset={resetAllGraphs}
      />
      <WidgetsContainer
        graphData={graphData ? [...graphData.deliveryBounce] : undefined}
        subHeader={t("pages.analytics.outbound.bounceRates")}
        storageKey="outboundBounceData"
      />
      <span className={styles.border} />
      <WidgetsContainer
        graphData={graphData ? [...graphData.portfolioSummary] : undefined}
        subHeader={t("pages.analytics.outbound.portfolio")}
        storageKey="outboundPortfolioData"
      />
    </main>
  );
}
