import { useState } from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Line,
  PieChart,
  Pie,
  Legend,
  Cell,
  ComposedChart,
  AreaChart,
  Area,
} from "recharts";

import { GraphTooltip } from "components/GraphTooltip";

import { COLORS } from "lib/colors";
import { formatNumber } from "lib/formatNumber";

import styles from "./Graph.module.scss";

const fontSize = 13;

function getColor(index: number) {
  const baseColor = [36, 61, 83];
  const opacity = 1 / (index + 0.3);
  return `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity})`;
}

const tickFormatter = (value: number) =>
  typeof value === "number" ? formatNumber(value, true) : value;

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const angle = props.width < 425;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        transform={angle ? "rotate(-35)" : undefined}
        fontSize={fontSize}
        letterSpacing={-0.8}
        textAnchor={angle ? "end" : "middle"}
        dy={14}
        dx={angle ? 12 : undefined}
        fill="#666"
      >
        {tickFormatter(payload.value)}
      </text>
    </g>
  );
};

type Props = {
  data: any;
};

export function Graph({ data }: Props) {
  const [hoverTarget, setHoverTarget] = useState<number>();
  const chartHeight = 340;
  const yAxisWidth = 15;

  if (data.type === "bar") {
    const customColors = data?.properties?.colors;

    return (
      <ResponsiveContainer height={chartHeight} className={styles.chart}>
        <BarChart data={data.data}>
          <XAxis
            tickLine={false}
            dataKey={data.xAxisKey}
            tickMargin={14}
            tick={CustomizedAxisTick}
            fontSize={fontSize}
          />
          <YAxis
            tickLine={false}
            tickMargin={12}
            fontSize={fontSize}
            width={yAxisWidth}
            tickFormatter={tickFormatter}
          />
          <CartesianGrid
            strokeDasharray="4 4"
            vertical={false}
            stroke={COLORS.grayLight}
          />
          <Tooltip
            cursor={false}
            // @ts-expect-error type error
            content={GraphTooltip}
            isAnimationActive={false}
          />
          {data.legend && (
            <Legend iconType="circle" wrapperStyle={{ paddingTop: 24 }} />
          )}
          {data.yAxisKey.map((key: any, parentIndex: number) => (
            <Bar
              key={parentIndex}
              dataKey={key}
              barSize={data?.barSize}
              radius={data.stacked ? undefined : [8, 8, 0, 0]}
              isAnimationActive={false}
              background={
                data.background ? { fill: COLORS.grayLight } : undefined
              }
              fill={customColors ? customColors[parentIndex] : undefined}
              stackId={data.stacked ? "stacked" : undefined}
            >
              {!data.stacked &&
                data.data.map((_: any, index: number) => (
                  <Cell
                    key={index}
                    fill={
                      customColors
                        ? customColors[parentIndex]
                        : hoverTarget === index
                          ? "#F26337"
                          : "#243D53"
                    }
                    onMouseOver={() => setHoverTarget(index)}
                    onMouseOut={() => setHoverTarget(undefined)}
                  />
                ))}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  if (data.type === "barHorizontal") {
    const customColors = data?.properties?.colors;

    return (
      <ResponsiveContainer height={chartHeight} className={styles.chart}>
        <BarChart data={data.data} layout="vertical">
          <YAxis
            tickLine={false}
            dataKey={data.xAxisKey}
            type="category"
            interval={0}
            fontSize={fontSize}
            width={yAxisWidth}
          />
          <XAxis
            tickLine={false}
            tickMargin={12}
            type="number"
            tickFormatter={tickFormatter}
          />
          <CartesianGrid
            strokeDasharray="4 4"
            vertical={false}
            stroke={COLORS.grayLight}
          />
          <Tooltip
            cursor={false}
            // @ts-expect-error type error
            content={GraphTooltip}
            isAnimationActive={false}
          />
          {data.legend && (
            <Legend iconType="circle" wrapperStyle={{ position: "relative" }} />
          )}
          {data.yAxisKey.map((key: any, parentIndex: number) => (
            <Bar
              key={`bar-${parentIndex}`}
              dataKey={key}
              radius={data.stacked ? undefined : [0, 8, 8, 0]}
              background={
                data.background ? { fill: COLORS.grayLight } : undefined
              }
              fill={customColors ? customColors[parentIndex] : undefined}
              stackId={data.stacked ? "stacked" : undefined}
              isAnimationActive={false}
            >
              {!data.stacked &&
                data.data.map((_: any, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      customColors
                        ? customColors[parentIndex]
                        : hoverTarget === index
                          ? "#962DFF"
                          : "#F0E5FC"
                    }
                    onMouseOver={() => setHoverTarget(index)}
                    onMouseOut={() => setHoverTarget(undefined)}
                  />
                ))}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  if (data.type === "line") {
    return (
      <ResponsiveContainer height={chartHeight}>
        {/* @ts-expect-error type error */}
        <LineChart data={data.data} overflow="visible">
          <XAxis
            tickLine={false}
            dataKey={data.xAxisKey}
            interval={0}
            tick={CustomizedAxisTick}
          />
          <YAxis
            tickLine={false}
            tickMargin={6}
            width={yAxisWidth}
            fontSize={fontSize}
          />
          <CartesianGrid
            strokeDasharray="4 4"
            vertical={false}
            stroke={COLORS.grayLight}
          />
          <Legend
            iconType="circle"
            wrapperStyle={{ top: -14 }}
            verticalAlign="top"
          />
          <Tooltip
            cursor={false}
            // @ts-expect-error type error
            content={GraphTooltip}
            isAnimationActive={false}
          />
          {data.yAxisKey.map((key: any, index: number) => (
            <Line
              key={index}
              name={data.properties?.names?.[index]}
              type={data.properties?.lineType ?? "monotone"}
              dot={data.properties?.dots ? { r: 8 } : false}
              activeDot={{ r: 8 }}
              dataKey={key}
              isAnimationActive={false}
              stroke={data.properties?.colors?.[index] ?? "#962DFF"}
              strokeWidth={2}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  }

  if (data.type === "pie") {
    const filteredData = data.data.filter((d: any) =>
      data.yAxisKey.includes(d.name),
    );

    return (
      <ResponsiveContainer height={chartHeight}>
        <PieChart>
          <Pie
            data={filteredData}
            dataKey={data.dataKey}
            fill="#8884d8"
            isAnimationActive={false}
          >
            {filteredData.map((_: any, index: number) => (
              <Cell key={index} fill={getColor(index)} />
            ))}
          </Pie>
          <Tooltip
            cursor={false}
            // @ts-expect-error type error
            content={GraphTooltip}
            isAnimationActive={false}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  if (data.type === "doughnut") {
    const filteredData = data.data.filter((d: any) =>
      data.yAxisKey?.includes(d.name),
    );

    return (
      <div className={styles.doughnutContainer}>
        <ResponsiveContainer height={chartHeight} width={300}>
          <PieChart>
            <Pie
              data={filteredData}
              dataKey={data.dataKey}
              fill="#8884d8"
              innerRadius={85}
              paddingAngle={3}
              isAnimationActive={false}
            >
              {filteredData.map((_: any, index: number) => (
                <Cell key={index} fill={getColor(index)} />
              ))}
            </Pie>
            <Tooltip
              cursor={false}
              // @ts-expect-error type error
              content={GraphTooltip}
              isAnimationActive={false}
            />
          </PieChart>
        </ResponsiveContainer>
        {data?.properties?.legend && (
          <div className={styles.legend}>
            {filteredData.map((d: any, index: number) => (
              <div key={index}>
                <span
                  style={{ backgroundColor: data.properties?.colors[index] }}
                ></span>
                <p>{d.name}</p>
                <p>{d.value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  if (data.type === "composed") {
    return (
      <ResponsiveContainer height={chartHeight}>
        {/* @ts-expect-error type error */}
        <ComposedChart data={data.data} overflow="visible">
          <XAxis
            tickLine={false}
            dataKey={data.xAxisKey}
            tickMargin={14}
            fontSize={fontSize}
          />
          <YAxis
            tickLine={false}
            tickMargin={12}
            fontSize={fontSize}
            width={yAxisWidth}
          />
          <CartesianGrid
            strokeDasharray="4 4"
            vertical={false}
            stroke={COLORS.grayLight}
          />
          <Tooltip
            cursor={false}
            // @ts-expect-error type error
            content={GraphTooltip}
            isAnimationActive={false}
          />
          <Line
            dataKey="total"
            dot={false}
            stroke={COLORS.pink}
            isAnimationActive={false}
          />
          {data.yAxisKey
            .filter((v: string) => v !== "total")
            .map((key: number, index: number) => (
              <Bar
                key={index}
                dataKey={key}
                barSize={data?.barSize}
                radius={[8, 8, 0, 0]}
                fill={data.properties.colors?.[index]}
              />
            ))}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }

  if (data.type === "areachart") {
    const purpleGradient = (
      <defs>
        <linearGradient id="themePurple" x1="0" y1="0" x2="0" y2="1">
          <stop offset="44.33%" stopColor={COLORS.secondary} />
          <stop offset="70.32%" stopColor={COLORS.secondary} />
          <stop offset="100%" stopColor="rgba(139, 132, 215, 0.3)" />
        </linearGradient>
      </defs>
    );

    const blueGradient = (
      <defs>
        <linearGradient id="themeBlue" x1="0" y1="0" x2="0" y2="1">
          <stop offset="44.33%" stopColor={COLORS.blueLight} />
          <stop offset="70.32%" stopColor={COLORS.blueLight} />
          <stop offset="100%" stopColor="rgba(78, 195, 224, 0.31)" />
        </linearGradient>
      </defs>
    );

    return (
      <ResponsiveContainer height={300}>
        {/* @ts-expect-error type error */}
        <AreaChart data={data.data} overflow="visible">
          {purpleGradient}
          {blueGradient}
          <XAxis
            tickLine={false}
            dataKey="name"
            tickMargin={14}
            interval={0}
            fontSize={fontSize}
          />
          <YAxis
            tickLine={false}
            tickMargin={12}
            fontSize={fontSize}
            width={yAxisWidth}
          />
          <CartesianGrid
            strokeDasharray="4 4"
            vertical={false}
            stroke={COLORS.grayLight}
          />
          <Tooltip
            cursor={false}
            // @ts-expect-error type error
            content={GraphTooltip}
            isAnimationActive={false}
          />
          <Area
            name="value"
            dot={false}
            activeDot={{ r: 8 }}
            dataKey="value"
            isAnimationActive={false}
            stroke={data.theme === "blue" ? COLORS.blueLight : COLORS.secondary}
            strokeWidth={2}
            fill={
              data.theme === "blue" ? "url(#themeBlue)" : "url(#themePurple)"
            }
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
