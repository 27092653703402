import { useTranslation } from "react-i18next";

import { Toggle } from "components/Toggle";

import styles from "./TableWebhooksMobile.module.scss";

export function TableWebhooksMobile({ rowData }: any) {
  const { t } = useTranslation();

  const enabled = rowData.find((row: any) => row.column.id === "enabled");
  const url = rowData.find((row: any) => row.column.id === "url");
  const event = rowData.find((row: any) => row.column.id === "event");

  return (
    <div className={styles.cardContainer}>
      <div className={styles.header}>
        <div>
          <div>
            <p>{url.renderValue()}</p>
          </div>
        </div>
        <div>
          <Toggle checked={enabled.renderValue()} onChange={() => undefined} />
        </div>
      </div>
      <div className={styles.footer}>
        <p>{`${t(
          "pages.webhooks.tableColumns.event",
        )}: ${event.renderValue()}`}</p>
      </div>
    </div>
  );
}
