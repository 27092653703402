import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";

import { Form } from "components/Form";

export function FormNewAPIKey() {
  const { t } = useTranslation();

  const formData = {
    validator,
    uiSchema: {
      "ui:submitButtonOptions": {
        parentProps: {
          hidden: true,
        },
      },
      readOnly: {
        "ui:widget": "checkbox",
        "ui:width": "40%",
      },
    },
    schema: {
      type: "object",
      required: ["readOnly"],
      properties: {
        readOnly: {
          type: "boolean",
          title: t("pages.apiKeys.tableColumns.readOnly"),
        },
      },
    },
  };

  return <Form id="new-apikey-form" {...formData} />;
}
