import { useTranslation } from "react-i18next";

import { CardKPI } from "components/CardKPI";
import { DashboardHeader } from "components/DashboardHeader";
import { WidgetsContainer } from "components/WidgetsContainer";

import { useHead } from "lib/hooks/useHead";
import {
  useGetAnalyticsCollectionsQuery,
  useGetAnalyticsKPIsQuery,
} from "lib/slices/mockJsonAPISlice";

import styles from "./PageCollections.module.scss";

export function PageCollections() {
  const { t } = useTranslation();
  const { data: collections } = useGetAnalyticsCollectionsQuery();
  const { data: kpis } = useGetAnalyticsKPIsQuery();
  useHead(t("pages.analytics.collections.header"));

  return (
    <main className={styles.main}>
      <DashboardHeader title={t("pages.analytics.header")} />
      <section>
        <WidgetsContainer
          header={t("pages.analytics.collections.header")}
          graphData={collections?.graphs}
          storageKey="collectionsData"
        />
      </section>
      <section className={styles.kpisContainers}>
        <h2>KPIs</h2>
        <div>
          {kpis &&
            kpis.kpis.map((kpi, index) => (
              <div key={index} className={styles.cardContainer}>
                <CardKPI
                  type={kpi.type}
                  progress={kpi.progress}
                  percentage={kpi.percentage}
                  graphData={kpi.data}
                />
              </div>
            ))}
        </div>
      </section>
    </main>
  );
}
