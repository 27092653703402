import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HOST } from "config/common";

const baseQuery = fetchBaseQuery({
  baseUrl: HOST,
  credentials: "include",
});
const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // api.dispatch(logout());
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDashboard: builder.query<Dashboard, void>({
      query: () => "/dashboard",
    }),
    getFiles: builder.query<Files, void>({
      query: () => "/files",
    }),
    getFilesByEntityId: builder.query<Files, string>({
      query: (id) => `/files?entity.id=${id}`,
    }),
    getFileById: builder.query<IFile, string>({
      query: (id) => `/files/${id}`,
    }),
    getAnalyticsCollections: builder.query<Collections, void>({
      query: () => "/analytics/collections",
    }),
    getAnalyticsKPIs: builder.query<{ kpis: KPI[] }, void>({
      query: () => "/analytics/kpis",
    }),
    getInboundCommunication: builder.query<InboundCommuniation, void>({
      query: () => "/analytics/inbound-communication",
    }),
    getOutboundCommunication: builder.query<OutboundCommuniation, void>({
      query: () => "/analytics/outbound-communication",
    }),
    getCustomers: builder.query<Entity[], void>({
      query: () => "/entities",
    }),
    getEntityById: builder.query<Entity, string>({
      query: (id) => `/entities/${id}`,
    }),
    getFailures: builder.query<Failure[], void>({
      query: () => "/failures",
    }),
    getReports: builder.query<Report[], void>({
      query: () => "/reports",
    }),
    getMembers: builder.query<Member[], void>({
      query: () => "/users",
    }),
    getAPIKeys: builder.query<APIKey[], void>({
      query: () => "/apiKeys",
    }),
    getWebhooks: builder.query<Webhook[], void>({
      query: () => "/webhooks",
    }),
    getUserDetails: builder.query<Member, void>({
      query: (email) => `/users?email=${email}`,
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useGetAnalyticsCollectionsQuery,
  useGetAnalyticsKPIsQuery,
  useGetFileByIdQuery,
  useGetFilesByEntityIdQuery,
  useGetFilesQuery,
  useGetInboundCommunicationQuery,
  useGetOutboundCommunicationQuery,
  useGetEntityByIdQuery,
  useGetCustomersQuery,
  useGetFailuresQuery,
  useGetReportsQuery,
  useGetMembersQuery,
  useGetAPIKeysQuery,
  useGetWebhooksQuery,
  useGetUserDetailsQuery,
} = apiSlice;
