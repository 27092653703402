import { Button } from "components/Button";
import { APIKey } from "components/PageAPIKeys";
import { Toggle } from "components/Toggle";

import { copyToClipboard } from "lib/helpers";

import styles from "./TableAPIKeysMobile.module.scss";

export function TableAPIKeysMobile({ rowData }: any) {
  const enabled = rowData.find((row: any) => row.column.id === "enabled");
  const apiKey = rowData.find((row: any) => row.column.id === "apiKey");

  return (
    <div className={styles.cardContainer}>
      <div className={styles.header}>
        <APIKey apiKey={apiKey.renderValue()} />
      </div>
      <div className={styles.footer}>
        <Toggle checked={enabled.renderValue()} onChange={() => undefined} />
        <div>
          <Button
            intent="clear"
            icon={{ name: "copypaste" }}
            onClick={() => copyToClipboard(apiKey.renderValue())}
          />
        </div>
      </div>
    </div>
  );
}
