import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "components/Form";

import { countryToCountryCode } from "lib/helpers";
import { useAddClientMutation } from "lib/slices/clientServiceAPISlice";

type Props = {
  closeModal: () => void;
};

export function FormSANewClient({ closeModal }: Props) {
  const { t } = useTranslation();
  const [addClient, { isLoading }] = useAddClientMutation();
  const [errors, setErrors] = useState();

  const onSubmit = (data: any) => {
    data.formData.countryCode = countryToCountryCode(data.formData.country);
    data.formData.country = undefined;

    addClient({
      ...data.formData,
      ...{
        templates: {},
        communicationTimeSettings: {},
      },
    })
      .unwrap()
      .then(() => closeModal())
      .catch((error) => setErrors(error.data.errors));
  };

  const formData = {
    onSubmit,
    uiSchema: {
      "ui:submitButtonOptions": {
        intent: "primary",
        icon: { name: "floppydisk", intent: "white" },
        disabled: isLoading,
      },
      name: {
        "ui:widget": "text",
      },
      legalEntity: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      orgNr: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      visibleContactAddress: {
        "ui:widget": "email",
        "ui:width": "50%",
      },
      visibleContactPhone: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      website: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      country: {
        "ui:widget": "select",
        "ui:placeholder": t("pages.account.form.country"),
        "ui:width": "50%",
      },
      zipCode: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      city: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      address1: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      bgBankAccount: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      iban: {
        "ui:widget": "text",
      },
      swiftBic: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
      vatNo: {
        "ui:widget": "text",
        "ui:width": "50%",
      },
    },
    schema: {
      type: "object",
      required: [
        "name",
        "legalEntity",
        "orgNr",
        "visibleContactAddress",
        "visibleContactPhone",
        "website",
        "country",
        "zipCode",
        "city",
        "address1",
        "bgBankAccount",
        "swiftBic",
        "iban",
        "vatNo",
      ],
      properties: {
        name: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.clientName"),
          maxLength: 100,
        },
        legalEntity: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.legalEntity"),
          maxLength: 100,
        },
        orgNr: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.orgNr"),
          maxLength: 20,
        },
        visibleContactAddress: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.email"),
          maxLength: 50,
        },
        visibleContactPhone: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.phone"),
          format: "phoneWithCountryCode",
          maxLength: 18,
        },
        website: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.website"),
          format: "website",
          maxLength: 50,
        },
        country: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.country"),
          enum: t("countries", { returnObjects: true }),
        },
        zipCode: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.zip"),
          minLength: 4,
          maxLength: 6,
          pattern: "^\\d*\\s*$",
        },
        city: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.city"),
          maxLength: 50,
        },
        address1: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.address"),
          maxLength: 50,
        },
        bgBankAccount: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.bgBankAccount"),
          maxLength: 8,
        },
        iban: {
          type: "string",
          title: t("pages.account.form.iban"),
          maxLength: 34,
        },
        swiftBic: {
          type: "string",
          title: t("pages.account.form.bicSwift"),
          minLength: 6,
          maxLength: 11,
        },
        vatNo: {
          type: "string",
          title: t("pages.superAdmin.clients.tableColumns.vat"),
          maxLength: 15,
        },
      },
    },
  };

  return <Form id="new-sa-client-form" {...formData} asyncErrors={errors} />;
}
