import { useTranslation } from "react-i18next";

import { DashboardHeader } from "components/DashboardHeader";
import { WidgetsContainer } from "components/WidgetsContainer";

import { useHead } from "lib/hooks/useHead";
import { useGetInboundCommunicationQuery } from "lib/slices/mockJsonAPISlice";

import styles from "./PageInbound.module.scss";

export function PageInbound() {
  const { t } = useTranslation();
  const { data } = useGetInboundCommunicationQuery();
  useHead(t("pages.analytics.inbound.header"));

  return (
    <main className={styles.main}>
      <DashboardHeader title={t("pages.analytics.header")} />
      <WidgetsContainer
        header={t("pages.analytics.inbound.header")}
        graphData={data?.graphs}
        storageKey="inboundData"
      />
    </main>
  );
}
