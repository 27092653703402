import { flexRender } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { formatCurrency } from "lib/formatNumber";

import styles from "./TableFailuresMobile.module.scss";

export function TableFailuresMobile({ rowData }: any) {
  const date = rowData.find((row: any) => row.column.id === "date");
  const fileIds = rowData.find((row: any) => row.column.id === "fileIds");
  const entityId = rowData.find((row: any) => row.column.id === "entityId");
  const balance = rowData.find(
    (row: any) => row.column.id === "principleBalance",
  );
  const error = rowData.find((row: any) => row.column.id === "error");

  return (
    <div className={styles.cardContainer}>
      <div className={styles.header}>
        <div className={styles.fileInfo}>
          <div>
            {fileIds
              .renderValue()
              .split(",")
              .map((fileId: string, i: number) => (
                <Link key={i} to={`/entities/file/${fileId}`}>
                  {fileId}
                </Link>
              ))}
          </div>
          <div>
            <Link to={`/entities/${entityId.renderValue()}`}>
              {entityId.renderValue()}
            </Link>
          </div>
        </div>
        <div>
          <p>
            {new Intl.DateTimeFormat("GB", {
              month: "short",
              day: "numeric",
              year: "numeric",
            }).format(new Date(date.renderValue()))}
          </p>
        </div>
      </div>
      <div className={styles.footer}>
        {flexRender(error.column.columnDef.cell, error.getContext())}
        <p>{formatCurrency(balance.renderValue())}</p>
      </div>
    </div>
  );
}
