import { useRef, useState, useEffect, useCallback, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { IState } from "app/store";
import { icons } from "assets/icons";

import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";

import styles from "./WidgetPanel.module.scss";
import { updateSelectedWidgets } from "./widgetPanelSlice";

type Props = {
  id: number;
  title: string;
  subTitle?: string;
  iconName: keyof typeof icons;
  onDragStart: () => void;
  renderContent?: () => ReactNode;
  renderEditContent?: (onClose: () => void) => ReactNode;
  renderMoveSelect?: () => ReactNode;
  storageKey: keyof Widgets;
};

export function WidgetCard({
  id,
  title,
  subTitle,
  iconName,
  onDragStart,
  renderContent,
  renderEditContent,
  storageKey,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedWidgets = useSelector(
    (state: IState) => state.widgetPanel.selectedWidgets[storageKey],
  );
  const widgetRef = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useState({
    isDragging: false,
    // TODO: Is there a better way rather than hardcode?
    // Hack to make the card not jump when you start dragging
    // 12 because we have 12px padding
    dX: 12,
    dY: 12,
  });

  const onTouchMove = (e: any) => {
    e.preventDefault();

    if (widgetRef.current && e.targetTouches.length === 1) {
      widgetRef.current.style.position = "fixed";
      widgetRef.current.style.height = "auto";
      const touch = e.targetTouches[0];

      setState((prevState) => ({
        ...prevState,
        dX: touch.clientX,
        dY: touch.clientY,
      }));
    }
  };

  const onTouchStart = () => {
    if (!widgetRef.current) {
      return;
    }

    onDragStart?.();

    const rect = widgetRef.current.getBoundingClientRect();
    widgetRef.current.style.width = rect.width + "px";
    widgetRef.current.style.opacity = "0.8";

    setState((prevState) => ({
      ...prevState,
      isDragging: true,
    }));
  };

  const onMouseMove = useCallback(
    (e: any) => {
      if (!state.isDragging) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        dX: prevState.dX + e.movementX,
        dY: prevState.dY + e.movementY,
      }));
    },
    [state.isDragging],
  );
  const onMouseDown = useCallback(() => {
    if (!widgetRef.current) {
      return;
    }

    onDragStart?.();

    const rect = widgetRef.current.getBoundingClientRect();
    widgetRef.current.style.position = "absolute";
    widgetRef.current.style.width = rect.width + "px";
    widgetRef.current.style.opacity = "0.8";

    setState((prevState) => ({
      ...prevState,
      isDragging: true,
    }));
  }, []);
  const onMouseUp = useCallback(() => {
    if (!state.isDragging || !widgetRef.current) {
      return;
    }

    widgetRef.current.style.position = "unset";
    widgetRef.current.style.width = "auto";
    widgetRef.current.style.opacity = "1";

    setState((prevState) => ({
      ...prevState,
      isDragging: false,
      dX: 12,
      dY: 12,
    }));
  }, [state.isDragging]);

  useEffect(() => {
    const widget = document.getElementById(`widget-${id}`);

    widget?.addEventListener("touchmove", onTouchMove);
    widget?.addEventListener("touchend", onMouseUp);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      widget?.removeEventListener("touchmove", onTouchMove);
      widget?.removeEventListener("touchend", onMouseUp);
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  return (
    <>
      <div
        className={styles.widgetCard}
        ref={widgetRef}
        style={{
          left: `${state.dX}px`,
          top: `${state.dY}px`,
        }}
      >
        <div className={styles.widgetCardHeader}>
          <div className={styles.title}>
            {iconName && <Icon name={iconName} size={24} />}
            <div>
              <h3>{subTitle}</h3>
              <h2>{title}</h2>
            </div>
          </div>
          <div className={styles.widgetCardCtas}>
            <Button
              id={`widget-${id}`}
              icon={{ name: "arrowmove" }}
              onMouseDown={onMouseDown}
              onTouchStart={onTouchStart}
            />
            {renderEditContent && (
              <Button
                icon={{ name: "magicUwand" }}
                onClick={() => setOpenModal(true)}
              />
            )}
            <button
              className={styles.selectBtn}
              onClick={() =>
                dispatch(updateSelectedWidgets({ id, storageKey }))
              }
            >
              {selectedWidgets.includes(id) && <span />}
            </button>
          </div>
        </div>
        <div className={styles.widgetCardBody}>
          {renderContent && renderContent()}
        </div>
      </div>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        header={t("widgetCard.editOptions")}
      >
        {renderEditContent && renderEditContent(() => setOpenModal(false))}
      </Modal>
    </>
  );
}
