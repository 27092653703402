import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import widgetPanelSlice from "components/WidgetPanel/widgetPanelSlice";

import { clientServiceAPISlice } from "lib/slices/clientServiceAPISlice";
import languageSlice from "lib/slices/languageSlice";
import { apiSlice } from "lib/slices/mockJsonAPISlice";
import themeSlice from "lib/slices/themeSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // We are ignoring these to avoid the non-serializable Redux warning.
        // In our widgetsData, we have renderContent() and renderEditContent()
        // which cannot be serialized.
        ignoredActions: ["widgetPanel/setData", "widgetPanel/updateDataById"],
        ignoredPaths: ["widgetPanel.entities", "widgetPanel.deletedWidgets"],
      },
    })
      .concat(apiSlice.middleware)
      .concat(clientServiceAPISlice.middleware),
  reducer: {
    widgetPanel: widgetPanelSlice,
    language: languageSlice,
    theme: themeSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [clientServiceAPISlice.reducerPath]: clientServiceAPISlice.reducer,
  },
});

setupListeners(store.dispatch);

export type IState = ReturnType<typeof store.getState>;

// Export RootState and AppDispatch
export type RootState = ReturnType<typeof store.getState>;
