import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { DashboardHeader } from "components/DashboardHeader";
import { Skeleton } from "components/Skeleton";

import { formatNumber } from "lib/formatNumber";
import { transformToTimeSeries } from "lib/helpers";
import { useHead } from "lib/hooks/useHead";
import {
  useGetCasesQuery,
  useGetCustomersQuery,
} from "lib/slices/clientServiceAPISlice";
import { useGetDashboardQuery } from "lib/slices/mockJsonAPISlice";

import styles from "./PageDashboard.module.scss";

export function PageDashboard() {
  const { t } = useTranslation();
  const { data } = useGetDashboardQuery();
  const { user } = useAuth();
  useHead(t("pages.dashboard.header"));

  const { data: customers } = useGetCustomersQuery();
  const { data: cases } = useGetCasesQuery();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!customers || !cases) {
      return;
    }

    const d = transformToTimeSeries(customers, cases).data;
    setChartData(d);
  }, [customers, cases]);

  return (
    <main className={styles.main}>
      <DashboardHeader title={t("pages.dashboard.header")} />
      <div className={styles.welcomeContainer}>
        <div className={styles.welcomeText}>
          <img src="/assets/waving-hand.png" alt="waving hand" />
          <p>
            <span>
              {t("pages.dashboard.hey", { account: user?.profile.name })}
            </span>
            {t("pages.dashboard.welcomeText")}
          </p>
        </div>
        <div className={styles.payoutContainer}>
          {!data && <Skeleton width={300} height={35} />}
          {data && (
            <>
              <p>{t("pages.dashboard.mtdPayout")}</p>
              <p>
                {t("currencyEUR", { amount: formatNumber(data?.mtdPayout) })}
              </p>
            </>
          )}
        </div>
      </div>

      <div className={styles.divider} />

      <div style={{ width: "100%", height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickMargin={14} fontSize={14} />
            <YAxis tickMargin={12} fontSize={14} />
            <Tooltip cursor={false} isAnimationActive={false} />
            <Legend iconType="circle" wrapperStyle={{ paddingTop: 24 }} />
            <Line
              type="monotone"
              dataKey="customerCount"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="caseCount" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </main>
  );
}
