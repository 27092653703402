export const COLORS = {
  primary: "var(--color-primary)",
  primaryLight: "var(--color-primary-light)",
  secondary: "var(--color-secondary)",
  blue: "var(--color-blue)",
  blueLight: "var(--color-blue-light)",
  neutral: "var(--color-neutral)",
  neutralLight: "var(--color-neutral-light)",
  warning: "var--color-warning)",
  invalid: "var(--color-invalid)",
  valid: "var(--color-valid)",
  black: "var(--color-black)",
  white: "var(--color-white)",
  coral: "var(--color-coral)",
  pink: "var(--color-pink)",
  grayLight: "var(--color-gray-light)",
  purpleLight: "var(--color-purple-light)",
};
