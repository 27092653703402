import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";

import { Form } from "components/Form";

export function FormNewWebhook() {
  const { t } = useTranslation();

  const formData = {
    validator,
    uiSchema: {
      "ui:submitButtonOptions": {
        parentProps: {
          hidden: true,
        },
      },
      url: {
        "ui:widget": "text",
        "ui:width": "60%",
      },
      event: {
        "ui:widget": "select",
        "ui:width": "40%",
      },
    },
    schema: {
      type: "object",
      required: ["event", "url"],
      properties: {
        url: {
          type: "string",
          title: t("pages.webhooks.tableColumns.channels"),
        },
        event: {
          type: "string",
          title: t("pages.webhooks.tableColumns.event"),
          enum: [t("claim"), t("payment")],
        },
      },
    },
  };

  return <Form id="new-webhook-form" {...formData} />;
}
