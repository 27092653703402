import { BaseInputTemplate } from "components/Form/BaseInputTemplate";

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
  value?: string;
};

export function SearchBox({ placeholder, onChange, value }: Props) {
  return (
    <BaseInputTemplate
      icon="magnifyingglass"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  );
}
