import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Button } from "components/Button";
import { Graph } from "components/Graph";
import { GraphEdit } from "components/GraphEdit";
import { WidgetPanel } from "components/WidgetPanel";
import { resetGraphs, setData } from "components/WidgetPanel/widgetPanelSlice";

import { widgetsStorage } from "lib/localStorage";

import styles from "./WidgetsContainer.module.scss";

type Props = {
  header: string;
  subHeader?: string;
  graphData: Graph[][] | undefined;
  storageKey: keyof Widgets;
  customReset?: () => void;
};

export function WidgetsContainer({
  header,
  subHeader,
  graphData,
  storageKey,
  customReset,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [widgetData, setWidgetData] = useState<Graph[][]>();

  useEffect(() => {
    let widgetData = widgetsStorage.getItem<Graph[][]>(storageKey);
    if (!widgetData && graphData) {
      widgetData = graphData;
      widgetsStorage.setItem(storageKey, widgetData);
      setWidgetData(widgetData);
    } else if (widgetData) {
      setWidgetData(widgetData);
    }
  }, [graphData]);

  useEffect(() => {
    if (!widgetData) {
      return;
    }

    const widgets = widgetData.map((row) =>
      row.map((graph) => {
        return {
          ...graph,
          renderContent: () => <Graph data={graph} />,
          renderEditContent: (closeModal: () => void) => (
            <GraphEdit
              id={graph.id}
              filters={graph.filters}
              storageKey={storageKey}
              closeModal={closeModal}
            />
          ),
        };
      }),
    );

    dispatch(setData({ storageKey, data: widgets }));
  }, [widgetData]);

  return (
    <div>
      {header && (
        <div className={styles.widgetsHeader}>
          <h2>{header}</h2>
          <div className={styles.widgetBtns}>
            <Button
              text={t("pages.dashboard.resetGraphs")}
              onClick={() => {
                if (customReset) {
                  customReset();
                } else {
                  graphData &&
                    dispatch(
                      resetGraphs({ storageKey, originalData: graphData }),
                    );
                  graphData && setWidgetData([...graphData]);
                }
              }}
              icon={{ name: "fastforwardclock" }}
            />
            <Button
              text={t("pages.dashboard.exportPDF")}
              icon={{ name: "downloadfile", intent: "white" }}
              intent="primary"
            />
          </div>
        </div>
      )}
      {subHeader && <h3 className={styles.subHeader}>{subHeader}</h3>}
      <WidgetPanel storageKey={storageKey} />
    </div>
  );
}
