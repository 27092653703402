import { formatNumber } from "lib/formatNumber";

import styles from "./GraphTooltip.module.scss";

type Props = {
  active: boolean;
  payload: { name: string; value: number }[];
  label: string;
};

export function GraphTooltip({ active, payload, label }: Props) {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <p>{label}</p>
        {payload.map((entry, index) => (
          <div key={index} className={styles.entry}>
            <p>{entry.name}</p>
            <p>{formatNumber(entry.value)}</p>
          </div>
        ))}
      </div>
    );
  }
}
