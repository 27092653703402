import { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { IState } from "app/store";
import { Widgets } from "types";

import { Button } from "components/Button";

import { stringToNumber } from "lib/helpers";
import useMediaQuery from "lib/hooks/useMediaQuery";

import breakpoints from "styles/mixins.scss";

import { NewRow } from "./NewRow";
import { WidgetCard } from "./WidgetCard";
import styles from "./WidgetPanel.module.scss";
import {
  reorderData,
  createNewRow,
  deleteSelectedWidgets,
} from "./widgetPanelSlice";

type Props = {
  storageKey: keyof Widgets;
};

export const WidgetPanel = ({ storageKey }: Props) => {
  const [dragId, setDragId] = useState<number | string | undefined>(undefined);
  const dispatch = useDispatch();
  const data = useSelector(
    (state: IState) => state.widgetPanel.entities[storageKey],
  );
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet})`);
  const selectedWidgets = useSelector(
    (state: IState) => state.widgetPanel.selectedWidgets[storageKey],
  );

  const onMouseUp = (e: any) => {
    if (!dragId) {
      return;
    }

    let elementsUnderneath;
    if (e.clientX && e.clientY) {
      elementsUnderneath = document.elementsFromPoint(e.clientX, e.clientY);
    } else {
      elementsUnderneath = document.elementsFromPoint(
        e.changedTouches[0].clientX,
        e.changedTouches[0].clientY,
      );
    }

    const dropArea = elementsUnderneath.find(
      (element) =>
        element.hasAttribute("data-new-row") ||
        element.hasAttribute("data-drop-area"),
    );

    if (dropArea?.getAttribute("data-storage-key") !== storageKey) {
      setDragId(undefined);
      return;
    }

    if (dropArea?.hasAttribute("data-drop-area")) {
      dispatch(
        reorderData({
          dragId,
          dropAreaId: stringToNumber(dropArea.id),
          storageKey,
        }),
      );
    } else if (dropArea?.hasAttribute("data-new-row")) {
      dispatch(
        createNewRow({
          dragId,
          dropAreaId: stringToNumber(dropArea.id) as number,
          storageKey,
        }),
      );
    }

    setDragId(undefined);
  };

  return (
    <div className={styles.gridContainer} id="widget-grid">
      {selectedWidgets.length > 0 && (
        <Button
          text="Delete"
          intent="danger"
          onClick={() => dispatch(deleteSelectedWidgets({ storageKey }))}
        />
      )}
      {data.map((row, i) => {
        const filteredRow = row.filter((c) => c.hidden !== true);
        return (
          <Fragment key={i}>
            <NewRow
              id={i}
              key={`new-row-${i}`}
              active={dragId ? true : false}
              data-storage-key={storageKey}
            />
            {filteredRow.map((column) => (
              <div
                id={String(column.id)}
                key={`column-${column.id}`}
                data-drop-area={true}
                data-storage-key={storageKey}
                className={styles.column}
                style={{
                  width: !isTablet ? "100%" : `${100 / filteredRow.length}%`,
                  opacity:
                    dragId && row.filter((c) => c.id !== dragId).length >= 2
                      ? 0.5
                      : 1,
                }}
                onMouseUp={onMouseUp}
                onTouchEnd={onMouseUp}
                role="gridcell"
                tabIndex={0}
              >
                <WidgetCard
                  id={column.id}
                  title={column.title}
                  subTitle={column.subTitle}
                  iconName={column?.icon}
                  renderContent={column.renderContent}
                  renderEditContent={column.renderEditContent}
                  renderMoveSelect={column.renderMoveSelect}
                  onDragStart={() => setDragId(column.id)}
                  storageKey={storageKey}
                />
              </div>
            ))}
          </Fragment>
        );
      })}
      <NewRow
        id={data.length}
        key={`new-row-${data.length}`}
        active={dragId ? true : false}
        data-storage-key={storageKey}
      />
    </div>
  );
};
