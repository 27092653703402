import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { DashboardHeader } from "components/DashboardHeader";
import { Icon } from "components/Icon";
import {
  TableErrorMessage,
  TableFailuresMobile,
} from "components/PageFailures";
import { Table } from "components/Table";

import { formatCurrency } from "lib/formatNumber";
import { useHead } from "lib/hooks/useHead";
import { useGetFailuresQuery } from "lib/slices/mockJsonAPISlice";

import styles from "./PageFailures.module.scss";

const columnHelper = createColumnHelper();

export function PageFailures() {
  const { t } = useTranslation();
  const { data } = useGetFailuresQuery();
  useHead(t("pages.failures.header"));

  const columns = [
    columnHelper.display({
      id: "icon",
      size: 50,
      cell: () => <Icon name="folderdelete" intent="black" />,
    }),
    columnHelper.accessor("date", {
      header: t("pages.failures.tableColumns.date"),
      cell: (info) =>
        new Intl.DateTimeFormat("GB", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }).format(new Date(info.getValue())),
      filterType: "date",
      filterFn: "inDateRange",
    }),
    columnHelper.accessor("fileIds", {
      accessorFn: (originalRow) => originalRow.fileIds.toString(),
      header: t("pages.failures.tableColumns.fileIds"),
      cell: (info) => (
        <div className={styles.fileIdColumn}>
          {info
            .getValue()
            .split(",")
            .map((fileId, index) => (
              <Link key={index} to={`/entities/file/${fileId}`}>
                {fileId}
              </Link>
            ))}
        </div>
      ),
      filterType: "text",
    }),
    columnHelper.accessor("entityId", {
      header: t("pages.failures.tableColumns.entityId"),
      size: 150,
      cell: (info) => (
        <Link to={`/entities/${info.getValue()}`}>{info.getValue()}</Link>
      ),
      filterType: "text",
    }),
    columnHelper.accessor("error", {
      header: t("pages.failures.tableColumns.error"),
      size: 150,
      cell: (info) => (
        <TableErrorMessage
          error={info.getValue()}
          message={info.row.original.message}
        />
      ),
      filterType: "checkboxes",
      filterFn: "arrIncludesSome",
    }),
    columnHelper.accessor("principleBalance", {
      header: t("pages.failures.tableColumns.balance"),
      cell: (info) => formatCurrency(info.getValue()),
      filterType: "range",
      filterFn: "inNumberRange",
    }),
  ];

  return (
    <main className={styles.main}>
      <DashboardHeader title={t("pages.failures.header")} />
      {data && (
        <Table
          data={data}
          columns={columns}
          filters={true}
          header={true}
          mobileElement={TableFailuresMobile}
        />
      )}
    </main>
  );
}
