import { classNames } from "lib/classNames";

import styles from "./Toggle.module.scss";

type Props = {
  text?: string;
  onChange: (checked: boolean) => void;
  checked?: boolean;
  slim?: boolean;
  intent?: string;
};

export function Toggle({
  text,
  onChange,
  checked = false,
  slim = false,
  intent = "primary",
}: Props) {
  const classSize = slim ? styles.toggleSlim : styles.toggleDefault;

  const _onChange = ({ target: { checked } }: any) => onChange(checked);

  return (
    <div
      className={classNames(styles.toggleContainer, {
        [styles.togglePrimary]: intent === "primary",
        [styles.toggleSecondary]: intent === "secondary",
      })}
    >
      <input
        type="checkbox"
        id="switch"
        defaultChecked={checked}
        className={classSize}
        onChange={_onChange}
      />
      <span className={classSize} />
      <label htmlFor="switch">{text}</label>
    </div>
  );
}
