import { useState } from "react";

import { Button } from "components/Button";

import { classNames } from "lib/classNames";

import styles from "./APIKey.module.scss";

type Props = {
  apiKey: string;
};

export function APIKey({ apiKey }: Props) {
  const [showKey, setShowKey] = useState(false);

  return (
    <div
      className={classNames(styles.apiKeyColumn, {
        [styles.apiKeyHidden]: !showKey,
      })}
    >
      <p>{apiKey}</p>
      <Button
        intent="clear"
        icon={{ name: showKey ? "visible" : "invisible", size: 14 }}
        onClick={() => setShowKey(!showKey)}
      />
    </div>
  );
}
