import { CoreRow, createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { DashboardHeader } from "components/DashboardHeader";
import { FormSANewClient } from "components/FormSANewClient";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";
import { TableClientsMobile } from "components/PageSAClients";
import { Table } from "components/Table";

import { useHead } from "lib/hooks/useHead";
import {
  useDeleteClientMutation,
  useGetClientsQuery,
} from "lib/slices/clientServiceAPISlice";

import styles from "./PageSAClients.module.scss";

const columnHelper = createColumnHelper<Client>();

export function PageSAClients() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetClientsQuery();
  const [softDelete] = useDeleteClientMutation();
  const [clientModalOpen, setClientModalOpen] = useState(false);
  useHead(t("pages.superAdmin.clients.header"));

  const items = data && data.data;

  const deleteClient = (row: CoreRow<Client>["original"]) => {
    softDelete(row.id);
  };

  const columns = [
    columnHelper.display({
      id: "icon",
      cell: () => <Icon name="bank" intent="primary" />,
    }),
    columnHelper.accessor("name", {
      header: t("pages.superAdmin.clients.tableColumns.clientName"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.accessor("legalEntity", {
      header: t("pages.superAdmin.clients.tableColumns.legalEntity"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.accessor("orgNr", {
      header: t("pages.superAdmin.clients.tableColumns.orgNr"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.accessor("address1", {
      id: "address",
      header: t("pages.superAdmin.clients.tableColumns.address"),
      cell: (info) => (
        <div className={styles.columnMultiple}>
          <p>{info.row.original.address1}</p>
          <p>{info.row.original.city}</p>
          <p>{info.row.original.zipCode}</p>
          <p>{info.row.original.countryCode}</p>
        </div>
      ),
      meta: {
        filterType: "text",
      },
      filterFn: (row, _, value) =>
        row.original.address1.toLowerCase().includes(value) ||
        row.original.city.toLowerCase().includes(value) ||
        row.original.zipCode.toLowerCase().includes(value) ||
        row.original.countryCode.toLowerCase().includes(value),
    }),
    columnHelper.accessor("visibleContactAddress", {
      header: t("pages.superAdmin.clients.tableColumns.email"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.accessor("visibleContactPhone", {
      header: t("pages.superAdmin.clients.tableColumns.phone"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.accessor("website", {
      header: t("pages.superAdmin.clients.tableColumns.website"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.accessor("bgBankAccount", {
      id: "bankAccount",
      header: t("pages.superAdmin.clients.tableColumns.bgBankAccount"),
      cell: (info) => (
        <div className={styles.columnMultiple}>
          <p>IBAN: {info.row.original.iban}</p>
          <p>BIC: {info.row.original.swiftBic}</p>
          <p>BG: {info.row.original.bgBankAccount}</p>
        </div>
      ),
      meta: {
        filterType: "text",
      },
      filterFn: (row, _, value) =>
        row.original.iban.toLowerCase().includes(value) ||
        row.original.swiftBic.toLowerCase().includes(value) ||
        row.original.bgBankAccount.toLowerCase().includes(value),
    }),
    columnHelper.accessor("vatNo", {
      header: t("pages.superAdmin.clients.tableColumns.vat"),
      meta: {
        filterType: "text",
      },
    }),
    columnHelper.display({
      id: "actions",
      header: t("pages.superAdmin.clients.tableColumns.actions"),
      cell: (info) => (
        <Button
          onClick={() => deleteClient(info.row.original)}
          icon={{ name: "recyclebin" }}
          intent="clear"
        />
      ),
    }),
  ];

  return (
    <>
      <main className={styles.main}>
        <DashboardHeader title={t("pages.superAdmin.clients.header")} />
        <Table
          data={items}
          columns={columns}
          filters={true}
          header={true}
          isLoading={isLoading}
          headerCta={
            <Button
              text={t("pages.superAdmin.clients.newClient")}
              icon={{ name: "addcircle" }}
              onClick={() => setClientModalOpen(true)}
            />
          }
          mobileElement={TableClientsMobile}
        />
      </main>
      <Modal
        header={t("pages.superAdmin.clients.newClient")}
        isOpen={clientModalOpen}
        onClose={() => setClientModalOpen(false)}
      >
        <FormSANewClient closeModal={() => setClientModalOpen(false)} />
      </Modal>
    </>
  );
}
