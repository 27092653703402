import { createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { DashboardHeader } from "components/DashboardHeader";
import { FormNewWebhook } from "components/FormNewWebhook";
import { Icon } from "components/Icon";
import { Modal } from "components/Modal";
import { TableWebhooksMobile } from "components/PageWebhooks";
import { Table } from "components/Table";
import { Toggle } from "components/Toggle";

import { useHead } from "lib/hooks/useHead";
import { useGetWebhooksQuery } from "lib/slices/mockJsonAPISlice";

import styles from "./PageWebhooks.module.scss";

const columnHelper = createColumnHelper();

export function PageWebhooks() {
  const { t } = useTranslation();
  const { data } = useGetWebhooksQuery();
  const [modalOpen, setModalOpen] = useState(false);
  useHead(t("pages.webhooks.header"));

  const columns = [
    columnHelper.display({
      id: "icon",
      size: 50,
      cell: () => <Icon name="linkchain" intent="black" />,
    }),
    columnHelper.accessor("event", {
      header: t("pages.webhooks.tableColumns.event"),
      filterType: "text",
    }),
    columnHelper.accessor("url", {
      header: t("pages.webhooks.tableColumns.channels"),
      filterType: "text",
    }),
    columnHelper.accessor("enabled", {
      header: t("pages.webhooks.tableColumns.enabled"),
      filterType: "checkboxes",
      filterFn: "arrIncludesSome",
      cell: (info) => (
        <div className={styles.enabledColumn}>
          <Toggle checked={info.getValue()} onChange={() => undefined} />
        </div>
      ),
    }),
  ];

  return (
    <>
      <main className={styles.main}>
        <DashboardHeader title={t("pages.webhooks.header")} />
        {data && (
          <Table
            data={data}
            columns={columns}
            header={true}
            headerCta={
              <Button
                onClick={() => setModalOpen(true)}
                text={t("pages.webhooks.addWebhook")}
                icon={{ name: "add", size: 16 }}
              />
            }
            mobileElement={TableWebhooksMobile}
          />
        )}
      </main>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <h2>{t("pages.webhooks.addWebhook")}</h2>
            <p>{t("newClaim.subHeader")}</p>
          </div>
          <FormNewWebhook />
          <div className={styles.btnContainer}>
            <Button
              text={t("save")}
              intent="primary"
              type="submit"
              form="new-webhook-form"
              icon={{ name: "floppydisk", intent: "white" }}
              slim={true}
            />
            <Button
              text={t("cancel")}
              icon={{ name: "delete1", size: 16 }}
              slim={true}
              onClick={() => setModalOpen(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
