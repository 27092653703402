import { RefObject, useState } from "react";
import { useTranslation } from "react-i18next";

import RJSForm from "@rjsf/core";
import { RegistryWidgetsType, RJSFValidationError } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";

import { ArrayFieldTemplate } from "components/Form/ArrayFieldTemplate";
import { BaseInputTemplate } from "components/Form/BaseInputTemplate";
import { SubmitButton } from "components/Form/ButtonTemplates";
import {
  CheckboxWidget,
  CheckboxesWidget,
} from "components/Form/CheckboxWidget";
import { DateWidget } from "components/Form/DateWidget";
import { FieldErrorTemplate } from "components/Form/FieldErrorTemplate";
import { FieldTemplate } from "components/Form/FieldTemplate";
import { FileWidget } from "components/Form/FileWidget";
import { ObjectFieldTemplate } from "components/Form/ObjectFieldTemplate";
import { RadioWidget } from "components/Form/RadioWidget";
import { SelectWidget } from "components/Form/SelectWidget";
import { TextareaWidget } from "components/Form/TextareaWidget";

const customTemplates = {
  BaseInputTemplate,
  FieldErrorTemplate,
  FieldTemplate,
  ObjectFieldTemplate,
  ButtonTemplates: { SubmitButton },
  ErrorListTemplate: () => undefined,
  ArrayFieldTemplate,
};
const customWidgets: RegistryWidgetsType = {
  SelectWidget,
  CheckboxWidget,
  CheckboxesWidget,
  RadioWidget,
  // @ts-expect-error strange type error
  DateWidget,
  FileWidget,
  TextareaWidget,
};

const customFormats = {
  phoneWithCountryCode: /^\+\d{1,3}\d{9,}$/,
  website:
    /^(https?:\/\/)(www\.)?([a-zA-Z0-9-]+\.){1,2}[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/,
};

const validator = customizeValidator({ customFormats });

interface Props {
  schema: any;
  formRef?: RefObject<any>;
  [key: string]: any;
}

export function Form(props: Props) {
  // Due to https://github.com/rjsf-team/react-jsonschema-form/issues/2395, there's a
  // bug in rjsf which resets the form state onSubmit, taking control of the state fixes
  // the issue, hence why we have it here.
  const [formData, setFormData] = useState();
  const { t } = useTranslation();

  function transformErrors(errors: RJSFValidationError[]) {
    return errors.map((error) => {
      if (error.property === ".password" && error.name === "pattern") {
        error.message = t("pages.register.passwordError");
      }
      if (error.params.format === "phoneWithCountryCode") {
        error.message = t("errors.countryPhoneNumber");
      }
      if (error.params.format === "website") {
        error.message = t("errors.website");
      }
      if(error.property === ".zipCode") {
        error.message = t("errors.zipCode");
      }

      return error;
    });
  }

  return (
    <RJSForm
      ref={props.formRef}
      // @ts-expect-error schema can be overwritten through ...props
      schema={props.schema}
      // @ts-expect-error type error
      templates={customTemplates}
      widgets={customWidgets}
      transformErrors={transformErrors}
      formData={formData}
      validator={validator}
      // eslint-disable-next-line react/prop-types
      extraErrors={props.asyncErrors}
      onChange={(e) => setFormData(e.formData)}
      {...props}
    />
  );
}
