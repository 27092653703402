import { Icon } from "components/Icon";

import styles from "./WidgetPanel.module.scss";

type Props = {
  id: any;
  active: boolean;
};

export function NewRow({ active, ...props }: Props) {
  return (
    <div className={styles.newRow} data-new-row={true} {...props}>
      <div style={{ opacity: active ? 1 : 0 }}>
        <span />
        <Icon name="addcircle" intent="neutral-light" />
        <span />
      </div>
    </div>
  );
}
