import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DashboardHeader } from "components/DashboardHeader";
import { Graph } from "components/Graph";
import { Button } from "components/Button";
import { restoreDeletedWidget } from "components/WidgetPanel/widgetPanelSlice";

import { useHead } from "lib/hooks/useHead";

import styles from "./PageIdleGraphs.module.scss";

export function PageIdleGraphs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const graphs = useSelector((state) => state.widgetPanel.deletedWidgets);
  useHead(t("pages.analytics.idle.header"));

  const deletedGraphs = Object.entries(graphs);
  const empty =
    deletedGraphs.map((g) => g[1].length === 0).filter((g) => g !== true)
      .length === 0;

  return (
    <main className={styles.main}>
      <DashboardHeader title={t("pages.analytics.header")} />
      <section className={styles.content}>
        <h2>{t("pages.analytics.idle.header")}</h2>
        <p>{t("pages.analytics.idle.description")}</p>
        {empty && (
          <div className={styles.emptyContainer}>
            <p>{t("pages.analytics.idle.empty")}</p>
          </div>
        )}
        <div className={styles.graphContainer}>
          {deletedGraphs.map((deletedGraph) =>
            deletedGraph[1].map((graph, graphIndex) => (
              <div key={graphIndex} className={styles.cardContainer}>
                <div className={styles.header}>
                  <p>{graph.title}</p>
                  <Button
                    text="Restore"
                    icon={{ name: "fastforwardclock" }}
                    onClick={() =>
                      dispatch(
                        restoreDeletedWidget({
                          storageKey: deletedGraph[0],
                          widgetId: graph.id,
                        })
                      )
                    }
                  />
                </div>
                <div className={styles.graph}>
                  <Graph data={graph} />
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </main>
  );
}
