import { CSSProperties } from "react";

import { COLORS } from "lib/colors";

import styles from "./Progress.module.scss";

type Props = {
  progress: number | string;
  size?: number;
  color: string;
};

interface CustomStyles extends CSSProperties {
  "--progress-color": string;
  "--progress-value": string;
  "--progress-size": string;
}

export function ProgressCircle({
  progress,
  size = 100,
  color = COLORS.blue,
}: Props) {
  return (
    <div
      role="progressbar"
      aria-valuenow={Number(progress)}
      aria-valuemin={0}
      aria-valuemax={100}
      style={
        {
          "--progress-value": `${progress}%`,
          "--progress-color": color,
          "--progress-size": `${size}px`,
        } as CustomStyles
      }
      className={styles.progressCircle}
    ></div>
  );
}
